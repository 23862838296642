import React, { Component } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import './index.less';

const VillageResourceList = Loader.loadBusinessComponent('VillageResourceList');
const IconFont = Loader.loadBaseComponent('IconFont');
const CommunityListContent = Loader.loadBusinessComponent('CommunityListContent');
const MesRefresh = Loader.loadBusinessComponent('CommunityRefreshButton');
const tabArr = [
  { name: '频繁出现人员', icon: 'icon-S_Photo_FrequencyOften', key: '2' },
  { name: '偶尔出现人员', icon: 'icon-S_Photo_FrequencyOnce', key: '1' },
  { name: '全部', icon: 'icon-S_Photo_Type', key: '3' }
];

const TabPane = Tabs.TabPane;
@Decorator.withEntryLog()
@withRouter
@Decorator.businessProvider('flowPerson', 'tab')
@observer
class flowPerson extends Component {
  constructor(props) {
    super(props);
    this.options = {
      page: 0
    };
    this.unoptions = {
      page: 0
    };
    this.allOptions = {
      page: 0
    };
    this.state = {
      selectIf: true,
      NewFaceList: [],
      UnLongExistList: [],
      allList: [],
      activeKey: '2',
      loading: true,
      total: 0,
      anotherTotal: 0,
      allTotal: 0,
      choseId: undefined,
      id: Math.random(),
      scrollId: Math.random(),
      LongLoadMore: true,
    };
    this.once= [true,true,true]
    this.initData();
  }
  initData() {
    SocketEmitter.on(SocketEmitter.eventName.updatePerson, this.ProcessHandle);
  }
  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updatePerson, this.ProcessHandle);
  }
  ProcessHandle = data => {
    let { activeKey } = this.state;
    const { flowPerson } = this.props;
    flowPerson.editSearchData({ offset: 0 }, activeKey);
    this.requestData(activeKey, false, true /* ,true */);
  };
  requestData = (type, loadmore, refresh) => {
    const { flowPerson } = this.props;
    let { NewFaceList, UnLongExistList, allList } = this.state;
    if (loadmore) {
      if (type == 1) {
        this.unoptions.page++;
        flowPerson.editSearchData({ offset: this.unoptions.page * 100 }, 1);
      }
      if (type == 2) {
        this.options.page++;
        flowPerson.editSearchData({ offset: this.options.page * 100 }, 2);
      }
      if (type == 3) {
        this.allOptions.page++;
        flowPerson.editSearchData({ offset: this.allOptions.page * 100 }, 3);
      }
    } else {
      this.setState({
        loading: true
      });
    }
    this.setState(
      {
        LongLoadMore: false
      },
      () => {
        Service.community
          .queryUnregisteredPeople(flowPerson[type == 1 ? 'FloatsearchOption' : type == 2 ? 'FloatsearchOptionUnappear' : 'allFloatSearchOption'])
          .then(res => {
            if (type == 1) {
              this.setState({
                NewFaceList: loadmore ? NewFaceList.concat(res.list) : res.list,
                total: res.total
              });
            }
            if (type == 2) {
              this.setState({
                UnLongExistList: loadmore ? UnLongExistList.concat(res.list) : res.list,
                anotherTotal: res.total
              });
            }
            if (type == 3) {
              this.setState({
                allList: loadmore ? allList.concat(res.list) : res.list,
                allTotal: res.total
              });
            }
            this.once[type - 1] = false
            this.setState(
              {
                loading: false
              },
              () => {
                if (refresh) {
                  this.recoverInitPage();
                }
              }
            );
            return Promise.resolve(res.list.length);
          })
          .then(length => {
            if (length >= 100) {
              this.setState({
                LongLoadMore: true
              });
            }
          })
          .catch(() => {
            this.setState({
              loading: false
            });
          });
      }
    );
  };
  /**刷新 */
  FreShen = (type = false, fresh) => {
    let {activeKey} = this.state
    let { flowPerson } = this.props;
    if (fresh) {
      flowPerson.editSearchData({ offset: 0 }, activeKey);
      this.requestData(activeKey,false,true);
      this.recoverInitPage();
    } else {
      this.requestData(activeKey,true,false);
    }
  };
  handleTableKey = key => {
    this.setState({
      activeKey: key,
      LongLoadMore: true,
      scrollId: Math.random()
    },() => {
      if(key == 1 && this.once[0] ){
        this.FreShen(false,true)
      }else if(key == 2 && this.once[1] ){
        this.FreShen(false,true)
      }else if(key == 3 && this.once[2]){
        this.FreShen(false,true)
      }
    });
  };
  /**处理社区总览跳转选中小区的问题 */
  componentDidMount() {
    const { flowPerson } = this.props;
    const id = Utils.queryFormat(this.props.history.location.search).id || '';
    flowPerson.initSearchData('all');
    if (id.length > 0) {
      LM_DB.get('parameter', id).then(res => {
        let activeKey = res.activeKey;
        let searchData = Object.assign(res.communitySearchdata, {
          keywords: '',
          faceFeature: ''
        });
        let villageIds = searchData && searchData.villageIds.length == 1 ? searchData.villageIds[0] : undefined;
        if (villageIds) {
          flowPerson.editSearchData({ villageIds: [villageIds] }, 'all');
        }
        flowPerson.editSearchData(searchData, activeKey);
        this.setState({
          activeKey,
          choseId: villageIds,
          selectIf: villageIds ? false : true
        });
        this.FreShen(false,true);
      });
    } else {
      this.FreShen(false,true);
    }
  }
  HandleNoVillageData = () => {
    this.setState({
      NewFaceList: [],
      UnLongExistList: [],
      allList: []
    });
  };
  /**处理上传图片与输入的查询内容 */
  handleSelctId = () => {
    this.setState({
      id: Math.random()
    },() => {
      this.once=[true,true,true]
    });
  };
  backTop = () => {
    this.setState({
      scrollId: Math.random()
    });
  };
  //初始化请求数据开始位置
  recoverInitPage = () => {
    this.options.page = 0;
    this.unoptions.page = 0;
    this.allOptions.page = 0;
    this.backTop();
  };
  mergeSearchData = (activeKey, id) => {
    let { flowPerson, tab, location } = this.props;
    LM_DB.add('parameter', {
      id,
      activeKey: activeKey,
      communitySearchdata: toJS(
        activeKey == 1 ? flowPerson.FloatsearchOption : activeKey == 2 ? flowPerson.FloatsearchOptionUnappear : flowPerson.allFloatSearchOption
      )
    }).then(() => {
      tab.goPage({
        moduleName: 'communityUnRegistered',
        location,
        isUpdate: true,
        data: { id },
        action: 'replace'
      });
    });
  };
  render() {
    const {
      selectIf,
      activeKey,
      total,
      anotherTotal,
      choseId,
      allList,
      id,
      scrollId,
      UnLongExistList,
      NewFaceList,
      loading,
      LongLoadMore,
      allTotal
    } = this.state;
    return (
      <div className="community_float_alarm_box">
        <div className="community_left_total">
          <VillageResourceList
            activeKey={activeKey}
            mergeSearchData={this.mergeSearchData}
            handleSelctId={this.handleSelctId}
            FreShen={this.FreShen}
            type="unregistered"
            HandleNoVillageData={this.HandleNoVillageData}
            choseId={choseId}
            selectIf={selectIf}
          />
        </div>
        <div className="community_right_container">
          <MesRefresh activeKey={activeKey} total={activeKey == 1 ? total : activeKey == 2 ? anotherTotal : allTotal} FreShen={this.FreShen} />
          <Tabs type="card" onChange={this.handleTableKey} activeKey={activeKey}>
            {tabArr.map((v, i) => (
              <TabPane
                tab={
                  <span style={{ fontSize: '16px' }}>
                    <IconFont type={v.icon} />
                    <span style={{ fontSize: '14px' }}>{v.name}</span>
                  </span>
                }
                key={v.key}
              />
            ))}
          </Tabs>
          <CommunityListContent
            allList={allList}
            LongLiveList={NewFaceList}
            RegisUnappList={UnLongExistList}
            mergeSearchData={this.mergeSearchData}
            scrollId={scrollId}
            requestData={this.requestData}
            activeKey={activeKey}
            longLoading={loading}
            id={id}
            total={activeKey == 1 ? total : activeKey == 2 ? anotherTotal : allTotal}
            type={activeKey == 1 ? 'resident' : activeKey == 2 ? 'float' : 'all'}
            recoverInitPage={this.recoverInitPage}
            LongLoadMore={LongLoadMore}
            isCover={false}
          />
        </div>
      </div>
    );
  }
}
export default flowPerson;
