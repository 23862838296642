(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("ReactRouterDOM"), require("mobxReact"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define("communityUnRegistered", ["React", "antd", "ReactRouterDOM", "mobxReact", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["communityUnRegistered"] = factory(require("React"), require("antd"), require("ReactRouterDOM"), require("mobxReact"), require("mobx"));
	else
		root["communityUnRegistered"] = factory(root["React"], root["antd"], root["ReactRouterDOM"], root["mobxReact"], root["mobx"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 